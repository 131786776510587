/**
 * @param {object} debt
 * @param {number|string} debt.fee
 * @param {number|string} debt.installmentCharges
 * @param {number|string} debt.installmentChargePeriod
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
export function setDebtFee (debt) {
  let fee = debt?.fee

  if (debt?.isDebtRegistry) {
    const installmentChargePeriod = debt?.installmentChargePeriod
    fee = parseFloat(debt?.installmentCharges)

    switch (installmentChargePeriod) {
      case 'MONTHLY':
        break
      case 'QUARTERLY':
        fee = fee / 4
        break
      case 'HALF_YEARLY':
        fee = fee / 6
        break
      case 'YEARLY':
        fee = fee / 12
        break
      default:
        fee = 0
        break
    }
  }

  return parseFloat(fee?.toFixed(2)) || 0
}
