import { hasProp } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function removeUnacceptedFields ({ fields = {}, form = {} } = {}) {
  if (hasProp(fields, 'global')) {
    if (hasProp(fields.global, 'showCoApplicant')) delete fields.global.showCoApplicant
    if (hasProp(fields.global, 'hasCoApplicant')) delete fields.global.hasCoApplicant
    if (hasProp(fields.global, 'refinance')) delete fields.global.refinance
    if (hasProp(fields.global, 'newRepaymentYears')) delete fields.global.newRepaymentYears
    if (hasProp(fields.global, 'repaymentYearsSimple')) delete fields.global.repaymentYearsSimple
    if (hasProp(fields.global, 'newLoanAmount')) delete fields.global.newLoanAmount
    if (hasProp(fields.global, 'loanAmountSimple')) delete fields.global.loanAmountSimple
    if (hasProp(fields.global, 'refinanceNewLoanAmount')) delete fields.global.refinanceNewLoanAmount
    if (hasProp(fields.global, 'totalDebt')) delete fields.global.totalDebt
    if (hasProp(fields.global, 'totalDebtMonthlyCost')) delete fields.global.totalDebtMonthlyCost
    if (hasProp(fields.global, 'refinanceAmountSimple')) delete fields.global.refinanceAmountSimple
    if (hasProp(fields.global, 'ad')) delete fields.global.ad
    if (hasProp(fields.global, 'brokerTerms')) delete fields.global.brokerTerms
    if (hasProp(fields.global, 'firstInteraction')) delete fields.global.firstInteraction
    if (hasProp(fields.global, 'bidId')) delete fields.global.bidId
    if (hasProp(fields.global, 'orgReferer')) delete fields.global.orgReferer
    if (hasProp(fields.global, 'channel')) delete fields.global.channel
    if (hasProp(fields.global, 'utm')) delete fields.global.utm
  }

  if (hasProp(fields, 'applicant')) {
    if (hasProp(fields.applicant, 'condominiumLoanAmount')) delete fields.applicant.condominiumLoanAmount
    if (hasProp(fields.applicant, 'uuid')) delete fields.applicant.uuid
  }

  if (hasProp(fields, 'coApplicant')) {
    if (hasProp(fields.coApplicant, 'condominiumLoanAmount')) delete fields.coApplicant.condominiumLoanAmount
    if (hasProp(fields.coApplicant, 'uuid')) delete fields.coApplicant.uuid
  }

  if (hasProp(fields, 'tracking')) {
    if (hasProp(fields.tracking, 'ad')) delete fields.tracking.ad
    if (hasProp(fields.tracking, 'firstInteraction')) delete fields.tracking.firstInteraction
  }

  // Remove all data that shouldn't be updated on addCoApplicant
  if (form?.global?.leadType?.value === 'addCoApplicant') {
    if (hasProp(fields, 'global')) {
      if (hasProp(fields.global, 'loanPurpose')) delete fields.global.loanPurpose
      if (hasProp(fields.global, 'refinanceAmount')) delete fields.global.refinanceAmount
    }

    if (hasProp(fields, 'debts')) delete fields.debts

    const ssn = fields?.applicant?.ssn
    fields.applicant = { ssn }
  }

  return fields
}
