import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setVersionNo ({ fields = {}, form = {} } = {}) {
  fields.global = fields?.global || {}
  const type = form?.global?.type?.value

  if (type === 'singleStep') {
    fields.global.versionNo = CONSTANTS.SINGLE_STEP_VERSION_NO
  } else if (type === 'singleStepPv') {
    fields.global.versionNo = CONSTANTS.SINGLE_STEP_PV_VERSION_NO
  } else if (type === 'multipleStepsPv') {
    fields.global.versionNo = CONSTANTS.MULTIPLE_STEPS_PV_VERSION_NO
  } else {
    fields.global.versionNo = CONSTANTS.MULTIPLE_STEPS_VERSION_NO
  }

  return fields
}
