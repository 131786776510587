import { hasProp } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} debt
 * @returns {boolean}
 */
export function validDebt (debt) {
  if (
    !debt ||
    !hasProp(debt, 'appNo') ||
    !hasProp(debt, 'ssn') ||
    !hasProp(debt, 'appCategory') ||
    !hasProp(debt, 'amount') ||
    !hasProp(debt, 'monthlyCost') ||
    !hasProp(debt, 'bank') ||
    !hasProp(debt, 'applicant')
  ) return false

  return debt.amount >= 0 && debt.monthlyCost <= debt.amount
}
