import { safeParseJson } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} key
 * @param {object} storage
 * @returns {boolean}
 */
export function clearOldStorage (key, storage) {
  if (!storage?.length) return false

  const item = storage?.getItem(key)
  if (!item) return false

  const parsedItem = safeParseJson(item)
  if (!parsedItem || parsedItem?.build?.hash === process.env.BUILD_HASH) return false

  storage?.removeItem(key)

  return true
}
