import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'
import { setMortgageDebt } from './set-mortgage-debt'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object} form
 * @returns {({}|{ssnCo, amount: (Object|number), bank: (Object|number), appNo, appCategory: (number|Object|null), type: string, monthlyCost: (Object|number), ssn, applicant: (Object|number)})[]}
 */
export function collectMortgageDebts ({ market, fields, form } = {}) {
  if (!market) return []

  const list = []
  const hasCoApplicant = form?.global?.hasCoApplicant?.value
  const livesWithCoApplicant = form?.applicant?.livesWithCoApplicant?.value
  const applicantHasPartner = [CONSTANTS.MARITAL_STATUSES.LIVE_TOGETHER, CONSTANTS.MARITAL_STATUSES.MARRIED].includes(form?.applicant?.maritalStatus?.value)
  const coApplicantHasPartner = [CONSTANTS.MARITAL_STATUSES.LIVE_TOGETHER, CONSTANTS.MARITAL_STATUSES.MARRIED].includes(form?.coApplicant?.maritalStatus?.value)

  const mortgageApplicant = setMortgageDebt({ market, fields, form })
  const mortgageApplicantPartner = applicantHasPartner && setMortgageDebt({ market, fields, form, applicant: 'applicant', partner: true })
  const mortgageApplicantCo = hasCoApplicant && !livesWithCoApplicant && setMortgageDebt({ market, fields, form, applicant: 'coApplicant' })
  const mortgageApplicantCoPartner = hasCoApplicant && !livesWithCoApplicant && coApplicantHasPartner && setMortgageDebt({ market, fields, form, applicant: 'coApplicant', partner: true })

  if (mortgageApplicant) list.push(mortgageApplicant)
  if (mortgageApplicantPartner) list.push(mortgageApplicantPartner)
  if (mortgageApplicantCo) list.push(mortgageApplicantCo)
  if (mortgageApplicantCoPartner) list.push(mortgageApplicantCoPartner)

  return list
}
