import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} email
 * @returns {Promise<any>}
 */
export async function verifyEmail (email) {
  return request({
    method: 'POST',
    url: '/service/common-gateway/api/v1/customer/validate',
    body: { email },
    failGracefully: true
  })
}
