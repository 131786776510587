import { calculateDebtRegistryChargeCard, calculateDebtRegistryCreditFacility, calculateDebtRegistryRepaymentLoan, roundUp } from '@ocp-zmarta/zmarta-cl'
import { setDebtFee } from './set-debt-fee'
import { setDebtInterestRate } from './set-debt-interest-rate'
import { setDebtTerms } from './set-debt-terms'

/**
 * @param {object} debt
 * @param {string} debt.debtType
 * @param {number} debt.amount
 * @param {number} debt.monthlyCost
 * @param {number} debt.interestRate
 * @param {number} debt.nominalInterestRate
 * @param {number|string} debt.terms
 * @param {number|string} debt.fee
 * @param {number|string} debt.installmentCharges
 * @param {number|string} debt.installmentChargePeriod
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
export function setDebtMonthlyCost (debt) {
  let monthlyCost = debt?.monthlyCost

  if (debt?.isDebtRegistry) {
    const debtType = debt?.debtType
    const amount = debt?.amount
    const fee = setDebtFee(debt)
    const terms = setDebtTerms(debt)
    const interestRate = setDebtInterestRate(debt)

    switch (debtType) {
      case 'creditFacility':
        monthlyCost = calculateDebtRegistryCreditFacility({
          amount,
          interestRate,
          fee,
          terms
        })?.monthly

        break
      case 'repaymentLoan':
        monthlyCost = calculateDebtRegistryRepaymentLoan({
          amount,
          interestRate,
          fee,
          terms
        })?.monthly

        break
      case 'chargeCard':
        monthlyCost = calculateDebtRegistryChargeCard({
          amount,
          terms
        })?.monthly

        break
    }
  }

  return roundUp(monthlyCost) || null
}
