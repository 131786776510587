/* istanbul ignore file */

import Vue from 'vue'
import requests from '../../requests'

export const resetTokens = async ({ market }) => {
  try {
    await requests.serviceAuth.oauth.reset({ market, vertical: Vue.prototype.VERTICAL })
    return true
  } catch (e) {
    return false
  }
}
