import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    active: false,
    type: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getLoader: state => state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async showLoader ({ commit, dispatch }) {
      await dispatch('modal/hideModal', {}, { root: true })
      return commit('mutateLoader', { active: true })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async hideLoader ({ commit }) {
      return commit('mutateLoader', { active: false })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateLoader (state, payload) {
      Vue.set(state, 'active', payload.active)
      Vue.set(state, 'type', payload.type)
    }
  }
}
