import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} uuid
 * @param {string} vertical
 * @param {string} role
 * @returns {Promise<any>}
 */
export async function initiateUuidAuthentication ({ uuid, vertical, role }) {
  return request({
    method: 'POST',
    url: '/cl-form/auth/initiate-uuid-authentication',
    body: {
      uuid,
      vertical,
      role
    }
  })
}
