import { request } from '@ocp-zmarta/zmarta-cl'
import { serviceAuthHeaders } from '../../../utils'

/**
 * @param {string} token
 * @param {string} market
 * @param {string} vertical
 * @returns {Promise<any>}
 */
export async function create ({ token, market, vertical }) {
  return request({
    method: 'GET',
    url: `/service/auth/api/v1/oauth/create/${token}`,
    headers: serviceAuthHeaders(market, vertical),
    credentials: 'include'
  })
}
