/**
 * @param {string} market
 * @param {object} form
 * @param {object} content
 * @param {boolean} coApplicant
 * @returns {string|*}
 */
export function applicantTitle ({ market, form = {}, content = {}, coApplicant = false } = {}) {
  if (!market) return ''

  const applicant = coApplicant ? 'coApplicant' : 'applicant'
  let applicantName = form?.[applicant]?.firstName?.value || content?.common?.[applicant]
  let stepName = content?.title

  if (!applicantName && !stepName && !applicantName) return ''
  if (!form?.global?.hasCoApplicant?.value) return stepName

  let pluralizedEnd = ''
  switch (market) {
    case 'fi':
      pluralizedEnd = 'n'
      break
    case 'se':
      pluralizedEnd = 's'
      break
    case 'no':
      pluralizedEnd = 's'
      break
  }

  const lastCharInName = applicantName
    ?.substr(applicantName.length - 1)
    ?.toLowerCase()
  applicantName = applicantName
    ?.toString()
    ?.charAt(0)
    ?.toUpperCase() + applicantName?.slice(1)
  applicantName = lastCharInName !== pluralizedEnd
    ? `${applicantName}${pluralizedEnd}`
    : applicantName
  stepName = stepName?.toLowerCase()

  return `${applicantName} ${stepName}`
}
