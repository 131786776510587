import { CONSTANTS, size } from '@ocp-zmarta/zmarta-cl'
import { setDebtKey } from './set-debt-key'

/**
 * @param {array} loans
 * @returns {[]}
 */
export function mergeStudentLoans (loans) {
  if (!size(loans)) return []

  let index = 0
  const loan = (applicant) => {
    const item = loans?.find(item => item.applicant === applicant)
    if (!size(item)) return {}

    return {
      ...item,
      amount: loans
        ?.filter(item => item.applicant === applicant)
        ?.reduce((curr, val) => { return curr + val.amount }, 0) || 0,
      monthlyCost: loans
        ?.filter(item => item.applicant === applicant)
        ?.reduce((curr, val) => { return curr + val.monthlyCost }, 0) || 0,
      type: setDebtKey({ debt: null, index: index++, type: 'studentLoan' })
    }
  }

  return [
    loan(CONSTANTS.DEBT_HOLDERS.APPLICANT),
    loan(CONSTANTS.DEBT_HOLDERS.APPLICANT_CO)
  ].filter(item => item.applicant)
}
