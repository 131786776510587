import { clone, size } from '@ocp-zmarta/zmarta-cl'
import { removeEmptyFieldGroups } from './remove-empty-field-groups'
import { removeUnacceptedFields } from './remove-unaccepted-fields'
import { setAppType } from './set-app-type'
import { setDebts } from './set-debts'
import { setDefaultValues } from './set-default-values'
import { setFieldValues } from './set-field-values'
import { setGlobals } from './set-globals'
import { setLivesWithCoApplicant } from './set-lives-with-co-applicant'
import { setRefinanceValues } from './set-refinance-values'
import { setTerms } from './set-terms'
import { setTracking } from './set-tracking'
import { setVersionNo } from './set-version-no'

/**
 * @param {string} brand
 * @param {string} market
 * @param {boolean} whiteLabel
 * @param {object} form
 * @param {object} tracking
 * @param {object} debts
 * @returns {object|undefined}
 */
export function prepareFormFields ({ brand, market, whiteLabel, form = null, tracking = null, debts = null } = {}) {
  if (!brand || !market || !size(form)) return {}

  let fields = {
    tracking: {},
    global: {},
    applicant: {},
    coApplicant: {},
    whiteLabel: {}
  }

  // The order of these functions shouldn't be changed
  // We're cloning all the input vars to be sure not to trigger any vuex mutations outside any mutation handlers
  fields = setFieldValues({ fields, form: clone(form) })
  fields = setDefaultValues({ market, fields, form: clone(form) })
  fields = setGlobals({ market, fields, form: clone(form) })
  fields = setTracking({ brand, market, whiteLabel, fields, form: clone(form), tracking: clone(tracking) })
  fields = setVersionNo({ fields, form: clone(form) })
  fields = setTerms({ fields, form: clone(form) })
  fields = setLivesWithCoApplicant({ market, fields, form: clone(form) })
  fields = setRefinanceValues({ fields, form: clone(form) })
  fields = setDebts({ market, fields, form: clone(form), debts: clone(debts) })
  fields = setAppType({ fields, form: clone(form) })
  fields = removeEmptyFieldGroups({ fields, form: clone(form) })
  fields = removeUnacceptedFields({ fields, form: clone(form) })

  return fields
}
