import { CONSTANTS, removeNullValuesFromObject } from '@ocp-zmarta/zmarta-cl'
import { defaultDebtValues } from './default-debt-values'

/**
 * @param {object} fields
 * @param {object} form
 * @param {string} applicant
 * @param {boolean} partner
 * @returns {*}
 */
export function setCondominiumDebt ({ fields, form, applicant = 'applicant', partner = false } = {}) {
  if (form?.[applicant]?.residenceType?.value !== CONSTANTS.RESIDENCE_TYPES.CONDOMINIUM) return

  const livesWithCoApplicant = form?.global?.hasCoApplicant?.value && form?.applicant?.livesWithCoApplicant?.value
  const hasPartner = [CONSTANTS.MARITAL_STATUSES.LIVE_TOGETHER, CONSTANTS.MARITAL_STATUSES.MARRIED].includes(form?.[applicant]?.maritalStatus?.value)

  let amount = form?.[applicant]?.condominiumLoanAmount?.value
  let holder = applicant === 'applicant'
    ? CONSTANTS.DEBT_HOLDERS.APPLICANT
    : CONSTANTS.DEBT_HOLDERS.APPLICANT_CO
  let type = applicant === 'applicant'
    ? 'condominium_applicant'
    : 'condominium_co_applicant'

  if (partner) {
    holder = applicant === 'applicant'
      ? CONSTANTS.DEBT_HOLDERS.APPLICANT_PARTNER
      : CONSTANTS.DEBT_HOLDERS.APPLICANT_CO_PARTNER
    type = applicant === 'applicant'
      ? 'condominium_applicant_partner'
      : 'condominium_co_applicant_partner'
  }

  amount = amount && (hasPartner || livesWithCoApplicant) ? amount / 2 : amount

  return removeNullValuesFromObject({
    ...defaultDebtValues(fields),
    amount: amount || 0,
    monthlyCost: 0,
    bank: 4,
    appCategory: 2,
    applicant: holder,
    type
  })
}
