import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @returns {{ssnCo: (Object|null), appNo: (Object|number), appCategory: (number|Object|null), ssn: (Object|string)}}
 */
export function defaultDebtValues (fields) {
  return {
    appNo: fields?.global?.appNo,
    ssn: fields?.applicant?.ssn || '',
    ssnCo: fields?.coApplicant?.ssn || null,
    appCategory: fields?.global?.appCategory,
    applicant: CONSTANTS.DEBT_HOLDERS.APPLICANT
  }
}
