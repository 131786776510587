import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} url
 * @returns {Promise<any>}
 */
export async function devLogin ({ ssn }) {
  return request({
    method: 'GET',
    url: `/service/mortgage-gateway/dev/login/customer/${ssn}/se`,
    credentials: 'include'
  })
}
