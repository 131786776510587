export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    attributes: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getAttributes: state => state.attributes
  }
}
