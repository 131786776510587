import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'
import { logWarning } from '../misc'

/**
 * @param {string} market
 * @param {object|undefined} debt
 * @param {string|number|null} debt.type
 * @param {string|null} debt.debtType
 * @param {string|number|null} debt.financialInstitutionId
 * @param {boolean|null} debt.isDebtRegistry
 * @param {string} type
 * @returns {*}
 */
export function setDebtBankId ({ market, debt, type } = {}) {
  if (!market) return 0

  let bankId = debt?.type
  const constants = CONSTANTS[market?.toUpperCase()]

  if (debt?.isDebtRegistry) {
    let fallback
    let map
    const debtType = debt?.debtType
    const debtRegistryId = debt?.financialInstitutionId

    switch (debtType) {
      case 'creditFacility':
        fallback = constants?.DEBT_DEFAULTS?.creditCard
        map = 'credit'

        break
      case 'repaymentLoan':
        fallback = constants?.DEBT_DEFAULTS?.otherLoan
        map = 'loan'

        break
      case 'chargeCard':
        fallback = constants?.DEBT_DEFAULTS?.chargeCard
        map = 'credit'

        break
    }

    if (!constants?.DEBT_REGISTRY_MAP?.[map]?.[debtRegistryId]) {
      logWarning('debts/setDebtBankId', {
        name: 'NOT_IN_DEBT_REGISTRY_MAP',
        message: debtRegistryId
      })
    }

    bankId = constants?.DEBT_REGISTRY_MAP?.[map]?.[debtRegistryId] || fallback
  }

  return bankId || constants?.DEBT_DEFAULTS?.[type] || 0
}
