import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    fragments: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getConfig: state => state,
    getFragments: state => state.fragments,
    getHost: (state, getters, rootState, rootGetters) => {
      const brandId = rootGetters['form/getForm']?.global?.brandId?.value || Vue.prototype.BRANDS.ZMARTA.ID
      let host = brandId === Vue.prototype.BRANDS.ZMARTA.ID
        ? Vue.prototype.BRANDS.ZMARTA.URL
        : Vue.prototype.BRANDS.BROKER.URL

      if (!host || Vue.prototype.LOCAL) {
        host = window.location.origin
      } else if (Vue.prototype.DEVELOPMENT) {
        host = host.replace('www', 'test')
      }

      return host
    }
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {},

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {}
}
