/**
 * @param {object} debt
 * @param {number|string} debt.interestRate
 * @param {number|string} debt.nominalInterestRate
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
export function setDebtInterestRate (debt) {
  let interestRate = debt?.interestRate

  if (debt?.isDebtRegistry) {
    interestRate = debt?.nominalInterestRate
  }

  return interestRate?.toFixed(2) || null
}
