import { request } from '@ocp-zmarta/zmarta-cl'
import { serviceAuthHeaders } from '../../../utils'

/**
 * @param {string} market
 * @param {string} vertical
 * @returns {Promise<any>}
 */
export async function refresh ({ market, vertical }) {
  return request({
    method: 'GET',
    url: '/service/auth/api/v1/oauth/refresh',
    headers: serviceAuthHeaders(market, vertical),
    credentials: 'include',
    failGracefully: true
  })
}
