import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'
import { setCondominiumDebt } from './set-condomonium-debt'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {({}|{ssnCo, amount: (Object|number), bank: (Object|number), appNo, appCategory: (number|Object|null), type: string, monthlyCost: (Object|number), ssn, applicant: (Object|number)})[]}
 */
export function collectCondominiumDebts ({ fields, form } = {}) {
  let list = []

  const hasCoApplicant = form?.global?.hasCoApplicant?.value
  const livesWithCoApplicant = form?.applicant?.livesWithCoApplicant?.value
  const applicantHasPartner = [CONSTANTS.MARITAL_STATUSES.LIVE_TOGETHER, CONSTANTS.MARITAL_STATUSES.MARRIED].includes(form?.applicant?.maritalStatus?.value)
  const coApplicantHasPartner = [CONSTANTS.MARITAL_STATUSES.LIVE_TOGETHER, CONSTANTS.MARITAL_STATUSES.MARRIED].includes(form?.coApplicant?.maritalStatus?.value)

  const condominiumDebtApplicant = setCondominiumDebt({ fields, form })
  const condominiumDebtApplicantPartner = applicantHasPartner && setCondominiumDebt({ fields, form, applicant: 'applicant', partner: true })
  const condominiumDebtApplicantCo = hasCoApplicant && !livesWithCoApplicant && setCondominiumDebt({ fields, form, applicant: 'coApplicant' })
  const condominiumDebtApplicantCoPartner = hasCoApplicant && !livesWithCoApplicant && coApplicantHasPartner && setCondominiumDebt({ fields, form, applicant: 'coApplicant', partner: true })

  if (condominiumDebtApplicant) list = list.concat(condominiumDebtApplicant)
  if (condominiumDebtApplicantPartner) list = list.concat(condominiumDebtApplicantPartner)
  if (condominiumDebtApplicantCo) list = list.concat(condominiumDebtApplicantCo)
  if (condominiumDebtApplicantCoPartner) list = list.concat(condominiumDebtApplicantCoPartner)

  return list
}
