import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} uuid
 * @returns {Promise<any>}
 */
export async function getCustomerInsurance ({ uuid }) {
  return request({
    method: 'POST',
    url: '/cl-form/epi/get-customer-insurance',
    body: { uuid }
  })
}
