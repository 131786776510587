import { CONSTANTS, hasProp } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setRefinanceValues ({ fields = {}, form = {} } = {}) {
  if (
    form?.global?.refinance?.value ||
    CONSTANTS.HIDE_REFINANCE_CHOICE_ON_PURPOSE.includes(fields?.global?.loanPurpose)
  ) {
    if (fields?.global?.newLoanAmount) {
      fields.global.loanAmount = fields.global.newLoanAmount
      delete fields.global.newLoanAmount
    }

    if (fields?.global?.newRepaymentYears) {
      fields.global.repaymentYears = fields.global.newRepaymentYears
      delete fields.global.newRepaymentYears
    }

    if (
      !hasProp(fields.global, 'refinanceAmount') &&
      form?.debts?.totalDebt?.value
    ) {
      fields.global.refinanceAmount = form?.debts?.totalDebt?.value
    }
  } else {
    if (hasProp(fields.global, 'refinanceAmount')) delete fields.global.refinanceAmount
  }

  return fields
}
