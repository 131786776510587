import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} customer
 * @returns {Promise<any>}
 */
export async function addLead (customer) {
  return request({
    method: 'POST',
    url: '/service/common-gateway/api/v1/customer/addLead',
    body: customer
  })
}
