import { usableValue } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setTerms ({ fields = {}, form = {} } = {}) {
  fields.global = fields?.global || {}
  fields.applicant = fields?.applicant || {}
  fields.coApplicant = fields?.coApplicant || {}

  fields.global.appTerms = true

  if (form?.global?.leadType?.value !== 'addCoApplicant') {
    if (usableValue(form?.applicant?.digitalCommunication?.value)) {
      const digitalCommunication = !!form?.applicant?.digitalCommunication?.value
      fields.applicant.digitalCommunication = digitalCommunication
      fields.coApplicant.digitalCommunication = digitalCommunication
    }

    if (usableValue(form?.applicant?.userAgreement?.value)) {
      const userAgreement = !!form?.applicant?.userAgreement?.value
      fields.applicant.userAgreement = userAgreement
      fields.coApplicant.userAgreement = userAgreement
    }

    if (usableValue(form?.applicant?.creditDataClub?.value)) {
      const creditDataClub = !!form?.applicant?.creditDataClub?.value
      fields.applicant.creditDataClub = creditDataClub
      fields.coApplicant.creditDataClub = creditDataClub
    }
  }

  return fields
}
