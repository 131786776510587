import { setCookie } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    locale: null,
    default: null,
    options: []
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getLocale: state => state.locale,
    getIsDefaultLocale: state => state.default === state.locale,
    getMyAccountUrl: (state, getters, rootState, rootGetters) => {
      const locale = state.locale?.toString()?.toUpperCase()
      if (!locale) return

      const host = rootGetters['config/getHost']
      const base = Vue.prototype.MY_ACCOUNT?.BASE?.[locale]
      if (!base) return

      const url = new URL(`${host}${base}`)
      if (Vue.prototype.TESTCAFE) url.searchParams.append('testcafe', 'true')

      return url.href
    },
    getMyAccountOffersUrl: (state, getters, rootState, rootGetters) => {
      const locale = state.locale?.toString()?.toUpperCase()
      if (!locale) return

      const host = rootGetters['config/getHost']
      const base = Vue.prototype.MY_ACCOUNT?.BASE?.[locale]
      const product = Vue.prototype.MY_ACCOUNT?.PRODUCT?.[locale]
      const offers = Vue.prototype.MY_ACCOUNT?.OFFERS?.[locale]
      if (!base || !product || !offers) return

      const url = new URL(`${host}${base}${product}${offers}`)
      const id = rootGetters['form/getForm']?.global?.appNo?.value || window.appNo

      if (id) url.searchParams.append('id', id)
      if (Vue.prototype.TESTCAFE) url.searchParams.append('testcafe', 'true')

      return url.href
    }
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setLocale ({ state, rootGetters }, { locale }) {
      if (!locale) return

      if (Vue.prototype.LOCAL && rootGetters['router/getRoute']?.meta?.fragment === true) {
        setCookie('locale', locale, 7, 'preferences')
        window.location.reload()
      } else {
        window.dispatchEvent(new CustomEvent('zmarta-header:change-language', {
          detail: { locale }
        }))
      }
    }
  }
}
