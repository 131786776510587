import { request } from '@ocp-zmarta/zmarta-cl'
import { serviceAuthHeaders } from '../../../utils'

/**
 * @param {string|null} token
 * @param {string} market
 * @param {string} vertical
 * @returns {Promise<any>}
 */
export async function collect ({ token, market, vertical }) {
  return request({
    method: 'GET',
    url: `/service/auth/api/v1/eid/collect/${token}`,
    headers: serviceAuthHeaders(market, vertical)
  })
}
