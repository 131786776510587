import { getElement, hasProp, parseDataType } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setFieldValues ({ fields = {}, form = {} } = {}) {
  // Loops through all the form groups
  for (const groupKey in form) {
    const group = form[groupKey]

    // Loops through all the fields in the field group
    for (const fieldKey of Object.keys(group)) {
      const field = group[fieldKey]

      // Add field with value if the field has been touched and is valid
      if (hasProp(fields, groupKey) && field.touched && field.valid && !field.exclude) {
        const dataType = form?.[group]?.[field]?.dataType
        fields[groupKey][fieldKey] = parseDataType(dataType, field.value)
      }
    }
  }

  if (
    form?.global?.type?.value === 'singleStep' &&
    form?.global?.subType?.value === 'sitespeed'
  ) {
    const loanAmount = getElement('#loan-amount-input')
    const repaymentYears = getElement('#repayment-years-input')

    fields.global.loanAmount = parseDataType(form?.global?.loanAmount?.dataType, loanAmount?.value)
    fields.global.repaymentYears = parseDataType(form?.global?.repaymentYears?.dataType, repaymentYears?.value)
  }

  return fields
}
