import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {number} id
 * @returns {Promise<any>}
 */
export async function sendReminder ({ id }) {
  return request({
    method: 'POST',
    url: '/cl-form/epi/send-reminder',
    body: { id }
  })
}
