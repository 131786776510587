export function analyticsFieldValue ({ type, value, options } = {}) {
  if (!type) return
  let output

  if (
    type === 'radio' ||
    type === 'checkbox'
  ) {
    output = value === 'true' || value === true ? 'yes' : 'no'
  } else if (
    type === 'date' &&
    value?.year &&
    value?.month
  ) {
    output = `${value?.year}-${value?.month}`
  } else if (type === 'select') {
    output = value

    if (options) {
      output = options
        ?.find(item => item.value === value)
        ?.label
        ?.toString()
        ?.toLowerCase()
        ?.replace(/å/g, 'a')
        ?.replace(/ä/g, 'a')
        ?.replace(/ö/g, 'o')
        ?.replace(/ /g, '-')
    }
  }

  return output
}
