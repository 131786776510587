/* istanbul ignore file */

import { isAuthError, isLeadError, isRateLimitError } from '@ocp-zmarta/zmarta-cl'
import requests from '../../requests'

/**
 * @param {string} metric
 * @param {object|null} labels
 * @param {object|null} error
 */
export function metric (metric, labels = {}, error = null) {
  if (isAuthError(error) || isRateLimitError(error) || isLeadError(error)) return

  requests.internal.metric(metric, labels)
    .then(() => true)
    .catch(() => false)
}
