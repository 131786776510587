import { CONSTANTS, getCookie, hasProp, missingRequiredField } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @param {boolean} whiteLabel
 * @returns {*}
 */
export function prepareCreateApplication ({ fields = {}, form = {}, whiteLabel = false } = {}) {
  form.global = form?.global || {}
  form.applicant = form?.applicant || {}

  form.global.mediaAffId = CONSTANTS.MEDIA_AFF_ID.LEAD

  // Check if any required field is missing
  const fieldNames = [
    ...Object.keys(form.global),
    ...Object.keys(form.applicant)
  ]
  if (missingRequiredField(fieldNames, CONSTANTS.CREATE_APPLICATION_REQUIRED_FIELDS)) return

  // Remove debts and appNo
  if (hasProp(form, 'debts')) delete form.debts
  delete form.global.appNo

  // Remove whiteLabel if not whiteLabel
  if (form?.whiteLabel && !whiteLabel) delete form.whiteLabel

  const zCid = getCookie('zCid')
  if (zCid) form.global.zCid = zCid

  return form
}
