import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @returns {Promise<any>}
 */
export async function getRedisData () {
  return request({
    method: 'POST',
    url: '/cl-form/epi/get-redis-data',
    withCredentials: true
  })
}
