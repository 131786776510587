import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @returns {Promise<any>}
 */
export async function initiate ({ market }) {
  return request({
    method: 'GET',
    url: '/service/debt-registry/api/v1/initiate',
    headers: { country: market },
    credentials: 'include'
  })
}
