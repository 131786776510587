import { hotjarTagRecording, isRulesEngineError } from '@ocp-zmarta/zmarta-cl'
import { metric } from '../misc'

// ? ******************************************************************************************
// ? ******************************************************************************************

/**
 * @param {string} type
 * @param {boolean} isDuplicate
 * @param {boolean} isActive
 * @param {boolean} isBlacklisted
 * @param {string} locale
 * @param {string} channel
 * @param {object} error
 */
export function createApplicationMetrics ({ type, isDuplicate = false, isActive = false, isBlacklisted = false, locale = undefined, channel = undefined, error = undefined } = {}) {
  if (!type) return

  // ? ***********
  // ? Success
  // ? ***********

  if (type === 'success') {
    metric('application', { action: 'create', status: 'success', locale, channel })
    hotjarTagRecording('cl/form/application/create/approved')
  }

  // ? ***********
  // ? Declined
  // ? ***********

  if (type === 'declined') {
    metric('application', { action: 'create', status: 'declined', locale, channel })
    hotjarTagRecording('cl/form/application/create/declined')
  }

  // ? ***********
  // ? Error
  // ? ***********

  if (type === 'error') {
    if (isDuplicate) {
      metric('application', { action: 'create', status: 'duplicate', locale, channel })
      hotjarTagRecording('cl/form/application/create/duplicate')
    } else if (isActive) {
      metric('application', { action: 'create', status: 'active', locale, channel })
      hotjarTagRecording('cl/form/application/create/active')
    } else if (isBlacklisted) {
      metric('application', { action: 'create', status: 'blacklisted', locale, channel })
      hotjarTagRecording('cl/form/application/create/blacklisted')
    } else if (isRulesEngineError(error)) {
      metric('application', { action: 'create', status: 'rules_engine', locale, channel })
      hotjarTagRecording('cl/form/application/create/error-rules-engine')
    } else {
      metric('application', { action: 'create', status: 'error', locale, channel }, error)
      hotjarTagRecording('cl/form/application/create/error')
    }
  }
}
