import { hasProp, hotjarTagRecording, hotjarTriggerEvent, likelyLoggedIn } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'
import requests from '../../requests'
import { logError, metric } from '../../utils'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    success: false,
    showModal: false,
    token: null,
    autoStartToken: null,
    showSecret: false,
    secret: null,
    status: null,
    hintCode: null,
    loading: false,
    verifyEid: false,
    userCancel: false,
    authenticatedAction: null,
    authenticatingFor: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getAuthentication: state => state,
    getAuthenticationIsPendingStart: state => state.status === 'start' || (state.status === 'pending' && state.hintCode === 'outstandingTransaction')
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setAuthentication ({ commit }, payload) {
      return commit('mutateAuth', payload)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEid ({ state, commit, dispatch, rootGetters }) {
      await dispatch('resetAuthentication')
      await dispatch('messages/resetStatusMessage', {}, { root: true })
      await dispatch('messages/resetModalMessage', {}, { root: true })

      if (state.authenticatingFor) {
        if (state.authenticatingFor === 'leads') {
          await Vue.prototype.ZGA.event.form.click({
            step: rootGetters['cl/formSteps/currentStepName'],
            label: 'leads-verify-customer'
          })
        } else if (state.authenticatingFor === 'debt-registry') {
          await Vue.prototype.ZGA.event.form.click({
            step: rootGetters['cl/formSteps/currentStepName'],
            label: 'debt-registry-verify-customer'
          })
        }
      }

      await commit('mutateAuth', {
        status: 'start',
        hintCode: 'start'
      })

      await requests.serviceAuth.oauth.reset({
        market: Vue.prototype.MARKET,
        vertical: Vue.prototype.VERTICAL
      })

      if (Vue.prototype.SWEDEN) {
        return await dispatch('initiateEidSe')
      } else if (Vue.prototype.NORWAY) {
        return await dispatch('initiateEidNo')
      } else if (Vue.prototype.FINLAND) {
        return await dispatch('initiateEidFi')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEidSe ({ commit, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      metric('authentication', { action: 'initiate_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (rootGetters['misc/getIsDesktop']) {
        await commit('mutateShowSecret', true)
      }

      if (eidProvider === Vue.prototype.EID_PROVIDERS.BANKID) {
        await dispatch('messages/setStatusMessage', {
          group: 'bankid',
          name: rootGetters['misc/getIsDesktop'] ? 'start' : 'startMobile'
        }, { root: true })
      }

      await commit('mutateUserCancel', false)
      await commit('mutateLoading', true)

      try {
        hotjarTagRecording('cl-form/eid/initiate')

        const { orderRef, autoStartToken } = await requests.serviceAuth.eid.initiate({
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          eidProvider
        })

        if (!orderRef || !autoStartToken) {
          Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

          if (!orderRef) metric('authentication', { action: 'initiate_eid', status: 'no_order_ref', eidProvider, vertical: Vue.prototype.VERTICAL })
          if (!autoStartToken) metric('authentication', { action: 'initiate_eid', status: 'no_autostart_token', eidProvider, vertical: Vue.prototype.VERTICAL })

          await dispatch('messages/setModalMessage', {
            group: eidProvider ?? 'authentication'
          }, { root: true })

          await commit('mutateLoading', false)
          await dispatch('resetAuthentication')

          return false
        }

        await commit('mutateAuth', {
          token: orderRef,
          autoStartToken
        })

        metric('authentication', { action: 'initiate_eid', status: 'success', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/initiate/success')

        return true
      } catch (e) {
        metric('authentication', { action: 'initiate_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/initiateEidSe', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: eidProvider ?? 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        hotjarTriggerEvent('cl-form/eid/initiate/error')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEidNo ({ commit, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      metric('authentication', { action: 'initiate_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'loggingIn'
      }, { root: true })
      await commit('mutateLoading', true)

      try {
        hotjarTagRecording('cl-form/eid/initiate')

        const ssn = rootGetters['form/getForm']?.applicant?.ssn?.value
        const phone = rootGetters['form/getForm']?.applicant?.phone?.value

        const { orderRef, url } = await requests.serviceAuth.eid.initiate({
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          eidProvider,
          ssn,
          phone
        })

        if (!orderRef || !url) {
          Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

          if (!orderRef) metric('authentication', { action: 'initiate_eid', status: 'no_order_ref', eidProvider, vertical: Vue.prototype.VERTICAL })
          if (!url) metric('authentication', { action: 'initiate_eid', status: 'no_url', eidProvider, vertical: Vue.prototype.VERTICAL })

          await dispatch('messages/setModalMessage', {
            group: 'authentication'
          }, { root: true })

          await commit('mutateLoading', false)
          await dispatch('resetAuthentication')

          return false
        }

        await commit('mutateAuth', {
          token: orderRef,
          verifyEid: true
        })

        metric('authentication', { action: 'initiate_eid', status: 'success', eidProvider, vertical: Vue.prototype.VERTICAL })

        return url
      } catch (e) {
        metric('authentication', { action: 'initiate_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/initiateEidNo', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        hotjarTriggerEvent('cl-form/eid/initiate/error')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async initiateEidFi ({ commit, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      metric('authentication', { action: 'initiate_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'loggingIn'
      }, { root: true })
      await commit('mutateLoading', true)

      try {
        hotjarTagRecording('cl-form/eid/initiate')
        const ssn = rootGetters['form/getForm']?.applicant?.ssn?.value

        const { orderRef, url } = await requests.serviceAuth.eid.initiate({
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          eidProvider,
          ssn
        })

        if (!orderRef || !url) {
          Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

          if (!orderRef) metric('authentication', { action: 'initiate_eid', status: 'no_order_ref', eidProvider, vertical: Vue.prototype.VERTICAL })
          if (!url) metric('authentication', { action: 'initiate_eid', status: 'no_url', eidProvider, vertical: Vue.prototype.VERTICAL })

          await dispatch('messages/setModalMessage', { group: 'authentication' }, { root: true })
          await commit('mutateLoading', false)
          await dispatch('resetAuthentication')

          return false
        }

        await commit('mutateAuth', {
          token: orderRef,
          verifyEid: true
        })

        metric('authentication', { action: 'initiate_eid', status: 'success', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/initiate/success')

        return url
      } catch (e) {
        metric('authentication', { action: 'initiate_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/initiateEidFi', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        hotjarTriggerEvent('cl-form/eid/initiate/error')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async secretEid ({ dispatch }) {
      if (Vue.prototype.SWEDEN) {
        return await dispatch('secretEidSe')
      } else if (Vue.prototype.NORWAY) {
        return await dispatch('secretEidNo')
      } else if (Vue.prototype.FINLAND) {
        return await dispatch('secretEidFi')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async secretEidSe ({ commit, state, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token || null

      metric('authentication', { action: 'secret_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'secret_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })
        return false
      }

      try {
        const { secret } = await requests.serviceAuth.eid.secret({
          token,
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL
        })

        await commit('mutateAuth', { secret })
        metric('authentication', { action: 'secret_eid', status: 'success', eidProvider, vertical: Vue.prototype.VERTICAL })

        return true
      } catch (e) {
        metric('authentication', { action: 'secret_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/secretEidSe', e)

        hotjarTriggerEvent('cl-form/eid/secret/error')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async secretEidNo () {
      return false
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async secretEidFi () {
      return false
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEid ({ dispatch }) {
      if (Vue.prototype.SWEDEN) {
        return await dispatch('collectEidSe')
      } else if (Vue.prototype.NORWAY) {
        return await dispatch('collectEidNo')
      } else if (Vue.prototype.FINLAND) {
        return await dispatch('collectEidFi')
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEidSe ({ commit, state, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value

      metric('authentication', { action: 'collect_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })
      await commit('mutateLoading', true)

      if (window.location.hash) {
        history.pushState('', document.title, window.location.pathname)
      }

      const token = state.token || null

      if (!token) {
        metric('authentication', { action: 'collect_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })

        await commit('mutateLoading', false)
        await commit('mutateUserCancel', false)
        await dispatch('resetAuthentication')

        return false
      }

      try {
        const { status, hintCode } = await requests.serviceAuth.eid.collect({
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          token
        })

        return await dispatch('handleCollect', { status, hintCode })
      } catch (e) {
        metric('authentication', { action: 'collect_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/collectEidSe', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: eidProvider ?? 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEidNo ({ commit, state, dispatch, rootGetters }) {
      if (!state.verifyEid) return false

      await commit('mutateLoading', true)
      await commit('mutateAuth', { verifyEid: false })

      if (state.success) {
        await commit('mutateLoading', false)
        return false
      }

      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token

      metric('authentication', { action: 'collect_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'collect_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })

        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }

      try {
        const { status, hintCode } = await requests.serviceAuth.eid.collect({
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          token
        })

        return await dispatch('handleCollect', { status, hintCode })
      } catch (e) {
        metric('authentication', { action: 'collect_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/collectEidNo', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async collectEidFi ({ state, commit, dispatch, rootGetters }) {
      if (!state.verifyEid) return false

      await commit('mutateLoading', true)
      await commit('mutateAuth', { verifyEid: false })

      if (state.success) {
        await commit('mutateLoading', false)
        return false
      }

      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token

      metric('authentication', { action: 'collect_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'collect_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })

        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }

      try {
        const { status, hintCode } = await requests.serviceAuth.eid.collect({
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          token
        })

        return await dispatch('handleCollect', { status, hintCode })
      } catch (e) {
        metric('authentication', { action: 'collect_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/collectEidFi', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async handleCollect ({ state, commit, dispatch, rootGetters }, { status, hintCode }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value

      await commit('mutateAuth', {
        status,
        hintCode
      })

      if (status === 'complete') {
        metric('authentication', { action: 'collect_eid', status: 'complete', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/collect/complete')

        return true
      } else if (status === 'failed') {
        metric('authentication', { action: 'collect_eid', status: hintCode === 'startFailed' ? 'start_failed' : 'failed', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/collect/failed')

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: eidProvider ?? 'authentication',
          name: hintCode
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      } else if (status === 'no-customer') {
        metric('authentication', { action: 'collect_eid', status: 'no_customer', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/collect/no-customer')

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: state.authenticatingFor === 'reApply'
            ? 'noCustomerReApply'
            : 'noCustomer'
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      } else if (!status) {
        metric('authentication', { action: 'collect_eid', status: 'unknown', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/collect/unknown')

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      } else {
        metric('authentication', { action: 'collect_eid', status: 'pending', eidProvider, vertical: Vue.prototype.VERTICAL })

        if (hintCode !== 'outstandingTransaction') {
          await commit('mutateShowSecret', false)
        }

        let group = eidProvider ?? 'authentication'
        let name = hintCode

        if (Vue.prototype.SWEDEN && !rootGetters['misc/getIsDesktop']) {
          name = `${name}Mobile`

          if (['userSign', 'started'].includes(hintCode)) {
            group = 'authentication'
            name = 'confirming'
          }
        }

        await dispatch('messages/setStatusMessage', {
          group,
          name
        }, { root: true })

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEid ({ commit, dispatch }) {
      Vue.prototype.EVENT_BUS.$emit('authentication:kill:secret')
      Vue.prototype.EVENT_BUS.$emit('authentication:kill:collect')

      if (Vue.prototype.SWEDEN) {
        return await dispatch('cancelEidSe')
      } else if (Vue.prototype.NORWAY) {
        return await dispatch('cancelEidNo')
      } else if (Vue.prototype.FINLAND) {
        return await dispatch('cancelEidFi')
      }

      await commit('mutateAuth', { showModal: false })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEidSe ({ commit, state, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token || null

      metric('authentication', { action: 'cancel_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'cancel_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })
        await commit('mutateLoading', false)

        return false
      }

      await dispatch('messages/setStatusMessage', {
        group: 'authentication',
        name: 'cancelling'
      }, { root: true })
      await commit('mutateLoading', true)

      try {
        await requests.serviceAuth.eid.cancel({
          token,
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL
        })

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          name: 'userForceCancel'
        }, { root: true })

        await commit('mutateLoading', false)
        await commit('mutateUserCancel', true)
        await dispatch('resetAuthentication')

        metric('authentication', { action: 'cancel_eid', status: 'success', eidProvider, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/eid/cancel/success')

        return true
      } catch (e) {
        metric('authentication', { action: 'collect_eid', status: 'error', eidProvider, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/cancelEidSe', e)

        hotjarTriggerEvent('cl-form/eid/cancel/error')

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEidNo ({ state, commit, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token || null

      metric('authentication', { action: 'cancel_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'cancel_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })
        await commit('mutateLoading', false)

        return false
      }

      await dispatch('messages/setModalMessage', {
        group: 'authentication',
        name: 'userForceCancel'
      }, { root: true })

      await commit('mutateLoading', false)
      await dispatch('resetAuthentication')

      hotjarTriggerEvent('cl-form/eid/cancel/success')

      return false
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async cancelEidFi ({ state, commit, dispatch, rootGetters }) {
      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token || null

      metric('authentication', { action: 'cancel_eid', status: 'started', eidProvider, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'cancel_eid', status: 'no_token', eidProvider, vertical: Vue.prototype.VERTICAL })
        await commit('mutateLoading', false)

        return false
      }

      await dispatch('messages/setModalMessage', {
        group: 'authentication',
        name: 'userForceCancel'
      }, { root: true })

      await commit('mutateLoading', false)
      await dispatch('resetAuthentication')

      hotjarTriggerEvent('cl-form/eid/cancel/success')

      return false
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async createOauth ({ commit, state, dispatch, rootGetters }, type = 'eid') {
      if (likelyLoggedIn()) return true

      const eidProvider = rootGetters['form/getForm']?.authentication?.eidProvider?.value
      const token = state.token || null

      metric('authentication', { action: 'create_oauth', status: 'started', eidProvider, type, vertical: Vue.prototype.VERTICAL })

      if (!token) {
        metric('authentication', { action: 'create_oauth', status: 'no_token', eidProvider, type, vertical: Vue.prototype.VERTICAL })

        await dispatch('messages/setModalMessage', {
          group: 'authentication'
        }, { root: true })

        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()
        return false
      }

      await dispatch('messages/setStatusMessage', {
        group: type === 'uuid'
          ? 'application'
          : 'authentication',
        name: 'confirming'
      }, { root: true })
      await commit('mutateLoading', true)

      try {
        await requests.serviceAuth.oauth.create({
          token,
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL
        })

        await commit('mutateAuth', {
          success: true,
          token: null,
          autoStartToken: null,
          showSecret: false,
          secret: null,
          url: null,
          status: null,
          hintCode: null
        })

        window.dispatchEvent(new window.Event('cl-form:logged-in'))

        if (type === 'eid') {
          await commit('mutateAuth', { showModal: false })
          await commit('mutateLoading', false)
        }

        metric('authentication', { action: 'create_oauth', status: 'success', eidProvider, type, vertical: Vue.prototype.VERTICAL })
        hotjarTriggerEvent('cl-form/oauth/create/success')

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: true }).catch()

        return true
      } catch (e) {
        metric('authentication', { action: 'oauth_create', status: 'error', eidProvider, type, vertical: Vue.prototype.VERTICAL }, e)
        logError('authentication/createOauth', e)

        Vue.prototype.ZGA.event.misc.loginAttempt({ success: false }).catch()

        await dispatch('messages/setModalMessage', {
          group: 'authentication',
          code: e?.code || e?.context?.code
        }, { root: true })

        await commit('mutateAuth', { showModal: false })
        await commit('mutateLoading', false)
        await dispatch('resetAuthentication')

        hotjarTriggerEvent('cl-form/oauth/create/error')

        return false
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    resetAuthentication ({ commit }) {
      commit('mutateAuth', {
        success: false,
        token: null,
        autoStartToken: null,
        showSecret: false,
        secret: null,
        loading: false,
        url: null,
        status: null,
        hintCode: null
      })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateAuth (state, payload) {
      if (hasProp(payload, 'success')) { Vue.set(state, 'success', payload.success) }
      if (hasProp(payload, 'showModal')) { Vue.set(state, 'showModal', payload.showModal) }
      if (hasProp(payload, 'token')) { Vue.set(state, 'token', payload.token) }
      if (hasProp(payload, 'autoStartToken')) { Vue.set(state, 'autoStartToken', payload.autoStartToken) }
      if (hasProp(payload, 'secret')) { Vue.set(state, 'secret', payload.secret) }
      if (hasProp(payload, 'loading')) { Vue.set(state, 'loading', payload.loading) }
      if (hasProp(payload, 'verifyEid')) { Vue.set(state, 'verifyEid', payload.verifyEid) }
      if (hasProp(payload, 'url')) { Vue.set(state, 'url', payload.url) }
      if (hasProp(payload, 'status')) { Vue.set(state, 'status', payload.status) }
      if (hasProp(payload, 'hintCode')) { Vue.set(state, 'hintCode', payload.hintCode) }
      if (hasProp(payload, 'authenticatedAction')) { Vue.set(state, 'authenticatedAction', payload.authenticatedAction) }
      if (hasProp(payload, 'authenticatingFor')) { Vue.set(state, 'authenticatingFor', payload.authenticatingFor) }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateUserCancel (state, cancel) {
      Vue.set(state, 'userCancel', cancel)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateLoading (state, loading) {
      Vue.set(state, 'loading', loading)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    mutateShowSecret (state, showSecret) {
      Vue.set(state, 'showSecret', showSecret)
    }
  }
}
