import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'
import { claGraphqlClient as client } from '../../graphql'
import { addExperimentEvent } from '../../graphql/queries/experiment'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {},

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getExperiments: state => Vue.prototype.TESTCAFE
      ? null
      : state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async activateExperiment ({ state, commit }, experimentKey) {
      if (!experimentKey) {
        console.warn('experimentKey is missing')
        return false
      }

      const experiment = state?.[experimentKey]
      if (!experiment) return false

      commit('mutateActive', {
        experimentKey,
        activated: true
      })

      return Vue.prototype.EXPERIMENTS.activate(experimentKey, {}, Vue.prototype.ZGA)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async trackEvent ({ rootGetters, state, rootState }, eventIds) {
      const appNo = rootGetters['form/getForm']?.global?.appNo?.value
      const isTestCafe = hasProp(rootState?.router?.route?.query, 'testcafe')

      if (!eventIds || !appNo || isTestCafe) return false

      try {
        const eventId = Vue.prototype.ZMARTA
          ? eventIds.ZMARTA
          : eventIds.BROKER

        if (!eventId) return false

        const activeExperiments = Object
          .values(state)
          .filter(experiment => (
            experiment.enabled === true &&
            experiment.activated === true
          ))

        if (!activeExperiments) return false

        for await (const key of Object.keys(activeExperiments)) {
          const value = activeExperiments[key]
          const userId = value?.userId
          const experimentId = value?.id
          const variationId = value?.variationId

          if (!userId || !experimentId || !variationId) continue

          await client.mutate({
            mutation: addExperimentEvent,
            variables: { data: { userId, appNo, experimentId, eventId, variationId } }
          })
        }

        return true
      } catch (e) {
        return false
      }
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateActive (state, payload) {
      if (hasProp(payload, 'activated') && hasProp(payload, 'experimentKey')) Vue.set(state[payload.experimentKey], 'activated', payload.activated)
    }
  }
}
