import { hasProp } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function prepareUpdateApplication ({ fields = {}, form = {} } = {}) {
  form.global = form?.global || {}
  form.global.appNo = fields?.global?.appNo?.value

  // Remove coApplicant if no SSN for coApplicant
  if (!form?.coApplicant?.ssn) delete form.coApplicant

  // Remove debts
  if (hasProp(form, 'debts')) {
    if (fields.debts?.useDebtRegistry?.value) {
      form.debts = form.debts
        .filter(debt => debt.type?.includes('otherLoan'))
    } else {
      delete form.debts
    }
  }

  // Remove whiteLabel
  if (form?.whiteLabel) delete form.whiteLabel

  return form
}
