import { randomString } from '@ocp-zmarta/zmarta-cl'

function getUserAgent () {
  return navigator.userAgent || navigator.vendor || window.opera
}

function getMobileOperatingSystem () {
  const userAgent = getUserAgent()

  if (/windows phone/i.test(userAgent)) {
    return 'windowsPhone'
  } else if (/android/i.test(userAgent)) {
    return 'android'
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'unknown'
}

function setRedirect () {
  const mobileOS = getMobileOperatingSystem()
  let redirect = null

  if (mobileOS === 'windowsPhone') {
    redirect = encodeURIComponent(window.location.href)
  } else if (mobileOS === 'ios') {
    if (navigator.userAgent.match('CriOS')) {
      redirect = encodeURIComponent('googlechromes://')
    } else if (navigator.userAgent.match('FxiOS')) {
      redirect = encodeURIComponent(`firefox://open-url?url=${window.location.href}`)
    } else if (navigator.userAgent.match('GSA')) {
      redirect = encodeURIComponent(`googleapp://open-url?url=${window.location.href}`)
    } else if (navigator.userAgent.match('EdgiOS')) {
      redirect = encodeURIComponent('microsoft-edge-https://')
    } else if (navigator.userAgent.match('FBAN')) {
      redirect = encodeURIComponent('fb://')
    } else {
      redirect = encodeURIComponent(`${window.location.href}#${randomString(4)}`)
    }
  }

  return redirect
}

function setHost () {
  const mobileOs = getMobileOperatingSystem()
  const userAgent = getUserAgent()

  if (
    (mobileOs === 'ios' && /GSA/.test(userAgent)) ||
    (mobileOs === 'ios' && /FBAN/.test(userAgent))
  ) {
    return 'https://app.bankid.com/'
  } else {
    return 'bankid:///'
  }
}

/**
 * @param {string|null} autostarttoken
 * @returns {string}
 */
export function setBankidStartUrl (autostarttoken) {
  return `${setHost()}?autostarttoken=${autostarttoken}&redirect=${setRedirect()}`
}
