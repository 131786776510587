/**
 * @param {object|null} debt
 * @param {number|string} debt.loanId
 * @param {boolean} debt.isDebtRegistry
 * @param {number|string} index
 * @param {string} type
 * @returns {*}
 */
export function setDebtKey ({ debt, index = 0, type } = {}) {
  if (!type) return

  let key = `${type}_${parseInt(index, 10) + 1}`

  if (debt?.isDebtRegistry) {
    key = `${type}_${debt?.loanId || parseInt(index, 10) + 1}`
  }

  return key
}
