/**
 * @param {object} debt
 * @param {number|string} debt.creditLimit
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
import { roundDown } from '@ocp-zmarta/zmarta-cl'

export function setDebtCreditLimit (debt) {
  return roundDown(debt?.creditLimit) || null
}
