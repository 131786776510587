import { size } from '@ocp-zmarta/zmarta-cl'
import { collectCondominiumDebts, collectDebts, collectMortgageDebts, collectRefinanceDebts, mergeStudentLoans } from '../debts'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object} form
 * @param {object} debts
 */
export function setDebts ({ market, fields = {}, form = {}, debts = null } = {}) {
  if (!market) return fields

  let list = []

  if (market === 'se') {
    const refinanceDebts = collectRefinanceDebts({ fields, form })

    list = [
      ...list,
      ...refinanceDebts
    ]
  }

  if (market === 'no') {
    const mortgages = collectMortgageDebts({ market, fields, form })
    const condominium = collectCondominiumDebts({ fields, form })
    const studentLoans = mergeStudentLoans(collectDebts({ market, fields, form, debts, type: 'studentLoan' }))
    const carLoans = collectDebts({ market, fields, form, debts, type: 'carLoan' })
    const otherLoans = collectDebts({ market, fields, form, debts, type: 'otherLoan' })

    list = [
      ...list,
      ...mortgages,
      ...condominium,
      ...studentLoans,
      ...carLoans,
      ...otherLoans
    ]
  }

  if (market === 'fi') {
    const mortgages = collectMortgageDebts({ market, fields, form })
    const creditCards = collectDebts({ market, fields, form, debts, type: 'creditCard' })
    const otherLoans = collectDebts({ market, fields, form, debts, type: 'otherLoan' })

    list = [
      ...list,
      ...mortgages,
      ...creditCards,
      ...otherLoans
    ]
  }

  if (size(list)) fields.debts = list

  return fields
}
