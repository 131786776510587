import authentication from './authentication'
import config from './config'
import content from './content'
import experiments from './experiments'
import features from './features'
import loader from './loader'
import messages from './messages'
import misc from './misc'
import modal from './modal'
import router from './router'
import translations from './translations'
import userAttributes from './user-attributes'
import verify from './verify'
import zmartaSession from './zmarta-session'

export default {
  build: {
    namespaced: true,
    state: {
      name: process.env.BUILD_NAME,
      date: process.env.BUILD_DATE,
      hash: process.env.BUILD_HASH
    }
  },
  authentication,
  config,
  content,
  experiments,
  features,
  loader,
  messages,
  modal,
  misc,
  router,
  translations,
  userAttributes,
  verify,
  zmartaSession
}
