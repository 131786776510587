/**
 * @param {object} debt
 * @param {number|string} debt.terms
 * @param {number|string} debt.debtType
 * @param {number|string} debt.installmentCharges
 * @param {number|string} debt.installmentChargePeriod
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
export function setDebtTerms (debt) {
  let terms = debt?.terms

  if (debt?.isDebtRegistry) {
    const debtType = debt?.debtType
    const installmentChargePeriod = debt?.installmentChargePeriod

    switch (installmentChargePeriod) {
      case 'MONTHLY':
        break
      case 'QUARTERLY':
        terms = terms * 4
        break
      case 'HALF_YEARLY':
        terms = terms * 6
        break
      case 'YEARLY':
        terms = terms * 12
        break
    }

    // 5 years in months as minimum
    if (['creditFacility', 'chargeCard'].includes(debtType) && terms <= 60) terms = 60
  }

  return terms || null
}
