import { initiateSentry, stateId } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'
import { createApp } from './app'
import { logError } from './utils'

const state = window[stateId(Vue.prototype.PROJECT_NAME)]
const { vertical, brand, market } = state?.router ?? {}
const route = state?.router?.route
const config = state?.config
const userAttributes = state?.userAttributes?.attributes
const isFragment = route?.isFragment
const sentryInfo = route?.sentryInfo

const { store } = createApp({ vertical, brand, market, route, userAttributes, config })

// we initialize the store state with the data injected from the server
if (state) store.replaceState(state)
Vue.prototype.EXPERIMENTS.attributes = store?.state?.userAttributes?.attributes

// sentry
try {
  await initiateSentry({ Vue, sentryInfo, isFragment })
} catch (e) {
  logError('failed to initialise sentry', e)
}

// add datalayer locally so we can test events
if (Vue.prototype.LOCAL || Vue.prototype.REVIEW) {
  window.dataLayer = []
}
