import { hasProp, isEmpty } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function removeEmptyFieldGroups ({ fields = {}, form = {} } = {}) {
  const leadType = form?.global?.leadType?.value

  // Remove tracking from object if not set or spread it into the global object if set
  if (isEmpty(fields.tracking)) {
    delete fields.tracking
  } else {
    fields.global = Object.assign(fields.global, fields.tracking)
    delete fields.tracking
  }

  // Remove global object if not set
  if (isEmpty(fields.global)) {
    delete fields.global
  }

  // Remove applicant object if not set
  if (isEmpty(fields.applicant)) {
    delete fields.applicant
  }

  // Remove coApplicant from object if not set or if no coApplicant is added
  if (
    isEmpty(fields.coApplicant) ||
    (!form?.global?.hasCoApplicant?.value && leadType !== 'addCoApplicant')
  ) {
    // Remove livesWithCoApplicant for main applicant when there is no coApplicant
    if (fields.applicant && hasProp(fields.applicant, 'livesWithCoApplicant')) {
      delete fields.applicant.livesWithCoApplicant
    }

    delete fields.coApplicant
  }

  // Remove debts object if not set
  if (isEmpty(fields.debts)) {
    delete fields.debts
  }

  // Remove whiteLabel object if not set
  if (isEmpty(fields.whiteLabel)) {
    delete fields.whiteLabel
  }

  return fields
}
