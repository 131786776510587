import { defaultDebtValues } from './default-debt-values'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {[]}
 */
export function collectRefinanceDebts ({ fields, form } = {}) {
  const list = []

  const debt = {
    ...defaultDebtValues(fields),
    amount: 0,
    monthlyCost: 0,
    appCategory: fields?.global?.appCategory,
    refinance: false,
    bank: 107,
    type: 'totalDebt'
  }

  if (
    form?.global?.refinance?.value &&
    parseInt(form?.debts?.totalDebt?.value, 10)
  ) {
    debt.refinance = true
    debt.amount = parseInt(form?.debts?.totalDebt?.value, 10)
    debt.monthlyCost = parseInt(form?.debts?.totalDebtMonthlyCost?.value, 10) || 1
  } else {
    return list
  }

  list.push(debt)

  return list
}
