import { isEmpty, toBool } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setLivesWithCoApplicant ({ market, fields = {}, form = {} } = {}) {
  if (!market) return fields

  if (form?.applicant?.livesWithCoApplicant?.value && !isEmpty(fields.coApplicant)) {
    fields.coApplicant.livesWithCoApplicant = fields?.applicant?.livesWithCoApplicant
    fields.coApplicant.maritalStatus = fields?.applicant?.maritalStatus
    fields.coApplicant.children = fields?.applicant?.children
    fields.coApplicant.residenceType = fields?.applicant?.residenceType

    if (market === 'no') {
      fields.applicant.spousesMonthlyIncome = fields?.coApplicant?.monthlyIncome
      fields.coApplicant.spousesMonthlyIncome = fields?.applicant?.monthlyIncome
    }

    if (market === 'fi') {
      if (fields?.applicant?.livedSince) {
        fields.coApplicant.livedSince = {
          month: fields?.applicant?.livedSince?.month,
          year: fields?.applicant?.livedSince?.year
        }
      }

      fields.coApplicant.residenceOwnershipType = fields?.applicant?.residenceOwnershipType
    }
  }

  if (!isEmpty(fields?.applicant)) {
    fields.applicant.livesWithCoApplicant = toBool(form?.applicant?.livesWithCoApplicant?.value)
  }

  if (!isEmpty(fields?.coApplicant)) {
    fields.coApplicant.livesWithCoApplicant = toBool(form?.applicant?.livesWithCoApplicant?.value)
  }

  return fields
}
