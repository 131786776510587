/* istanbul ignore file */

import Vue from 'vue'
import requests from '../../requests'
import { resetTokens } from './reset-tokens'

export const refreshToken = async ({ market }) => {
  try {
    const response = await requests.serviceAuth.oauth.refresh({ market, vertical: Vue.prototype.VERTICAL })
    const refreshed = response?.status === 'refreshed'

    if (refreshed) {
      Vue.prototype.EVENT_BUS.$emit('refresh-tokens:start-poll')
    } else {
      Vue.prototype.EVENT_BUS.$emit('refresh-tokens:stop-poll')
      await resetTokens({ market: Vue.prototype.MARKET })
    }

    return true
  } catch (e) {
    Vue.prototype.EVENT_BUS.$emit('refresh-tokens:stop-poll')
    await resetTokens({ market: Vue.prototype.MARKET })

    return false
  }
}
