import * as companyIdentifier from './company-identifier'
import * as internal from './internal'
import * as mortgageGateway from './mortgage-gateway'
import * as serviceAuth from './service-auth'
import * as serviceCommonGateway from './service-common-gateway'
import * as serviceDebtRegistry from './service-debt-registry'
import * as serviceElectricity from './service-electricity'

export default {
  internal,
  mortgageGateway,
  serviceAuth,
  serviceCommonGateway,
  serviceDebtRegistry,
  companyIdentifier,
  serviceElectricity
}
