import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} debt
 * @param {number|string} debt.holder
 * @param {boolean} debt.coBorrower
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
export function setDebtHolder (debt) {
  let holder = debt?.holder

  if (debt?.isDebtRegistry) {
    const coBorrower = debt?.coBorrower

    switch (coBorrower) {
      case 0:
        holder = CONSTANTS.DEBT_HOLDERS.APPLICANT
        break
      case 1:
        holder = CONSTANTS.DEBT_HOLDERS.APPLICANT_IS_CO_BORROWER
        break
      case 2:
        holder = CONSTANTS.DEBT_HOLDERS.APPLICANT_CO_IS_CO_BORROWER
        break
    }
  }

  return holder || CONSTANTS.DEBT_HOLDERS.APPLICANT
}
