import { size } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} state
 * @param {string} vertical
 * @param {boolean} persist
 * @returns {{}}
 */
export function setFormStorage (state, vertical, persist = false) {
  let output = {}

  if (vertical === 'cl') {
    output = {
      affiliateSignup: {},
      applicant: {},
      creditCard: {},
      coApplicant: {},
      debts: {},
      debtRegistry: {},
      global: {},
      loanIndicator: {},
      loanCalculator: {},
      optOut: {},
      whiteLabel: {}
    }
  } else if (vertical === 'sme') {
    output = {
      loan: {},
      company: {},
      shareholder: {},
      guarantor: {},
      loanWithSecurity: {}
    }
  } else if (vertical === 'mortgage') {
    output = {
      application: {},
      cars: {},
      compare: {},
      products: {}
    }
  } else if (vertical === 'electricity') {
    output = {
      calculator: {}
    }
  } else if (vertical === 'epi') {
    output = {
      application: {},
      customer: {},
      telemarketing: {}
    }
  } else if (vertical === 'insurance') {
    output = {
      car: {},
      company: {}
    }
  }

  if (!size(output)) return output

  for (const group of Object.keys(output)) {
    const fields = state[group]
    if (!fields) continue

    for (const field of Object.keys(fields)) {
      const item = state[group]?.[field]

      if (
        (persist && !item.persist) ||
        (!persist && item.persist)
      ) continue

      const value = item?.value
      const touched = item?.touched

      output[group][field] = { value, touched }
    }
  }

  return persist
    ? output
    : {
      visibleFieldsAreValid: true,
      ...output
    }
}
