/* istanbul ignore file */

import requests from '../../requests'

/**
 * @param {string} name
 * @param {object|array|string} info
 * @param {object} additionalData
 */
export function logInfo (name, info, additionalData = {}) {
  const data = {
    info: JSON.stringify(info),
    ...additionalData
  }

  requests.internal.log('info', name, data)
    .then(() => true)
    .catch(() => false)
}
