import { CONSTANTS, removeNullValuesFromObject } from '@ocp-zmarta/zmarta-cl'
import { defaultDebtValues } from './default-debt-values'
import { setDebtBankId } from './set-debt-bank-id'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object} form
 * @param {string} applicant
 * @param {boolean} partner
 * @returns {*}
 */
export function setMortgageDebt ({ market, fields, form, applicant = 'applicant', partner = false } = {}) {
  if (!market || !form?.[applicant]?.hasMortgage?.value) return

  const livesWithCoApplicant = form?.global?.hasCoApplicant?.value && form?.applicant?.livesWithCoApplicant?.value

  let amount = form?.[applicant]?.mortgageAmount?.value
  let monthlyCost = form?.[applicant]?.mortgageMonthlyCost?.value
  let holder = (livesWithCoApplicant && form?.[applicant]?.mortgageHolder?.value) || applicant === 'applicant'
    ? CONSTANTS.DEBT_HOLDERS.APPLICANT
    : CONSTANTS.DEBT_HOLDERS.APPLICANT_CO
  let type = applicant === 'applicant'
    ? 'mortgage_applicant'
    : 'mortgage_co_applicant'

  if (partner) {
    amount = form?.[applicant]?.mortgageAmountPartner?.value
    monthlyCost = form?.[applicant]?.mortgageMonthlyCostPartner?.value

    if (!amount || !monthlyCost) return

    type = applicant === 'applicant'
      ? 'mortgage_applicant_partner'
      : 'mortgage_co_applicant_partner'
    holder = applicant === 'applicant'
      ? CONSTANTS.DEBT_HOLDERS.APPLICANT_PARTNER
      : CONSTANTS.DEBT_HOLDERS.APPLICANT_CO_PARTNER
  }

  return removeNullValuesFromObject({
    ...defaultDebtValues(fields),
    amount: amount || 0,
    monthlyCost: monthlyCost || 0,
    bank: setDebtBankId({ market, debt: { type: form?.[applicant]?.mortgageBank?.value }, type: 'mortgage' }),
    appCategory: 2,
    applicant: holder,
    type
  })
}
