import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} source
 * @param {string} to
 * @param {string} subject
 * @param {string|null} text
 * @param {string|null} html
 * @returns {Promise<any>}
 */
export async function email ({ source, to, subject, text = null, html = null }) {
  return request({
    method: 'POST',
    url: '/cl-form/email',
    body: {
      source,
      to,
      subject,
      text,
      html
    }
  })
}
