import { CONSTANTS } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setGlobals ({ market, fields = {}, form = {} } = {}) {
  if (!market) return fields

  const constants = CONSTANTS[market?.toUpperCase()]
  fields.global = fields?.global || {}

  fields.global.brandId = form?.global?.brandId?.value || constants?.BRANDS?.ZMARTA?.ID
  fields.global.appCategory = CONSTANTS.APP_CATEGORY.LOAN.ID
  fields.global.mediaId = CONSTANTS.MEDIA_ID.INTERNET

  return fields
}
