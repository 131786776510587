import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} url
 * @returns {Promise<any>}
 */
export async function postalCity ({ postalCode }) {
  return request({
    method: 'GET',
    url: `/service/electricity/se/helper/postalcity/${postalCode}`,
    failGracefully: true
  })
}
