/**
 * @param {string} market
 * @param {object} debt
 * @param {number|string} debt.information
 * @param {boolean} debt.debtType
 * @param {boolean} debt.isDebtRegistry
 * @returns {*}
 */
export function setDebtInformation ({ market, debt } = {}) {
  let information = debt?.information

  if (debt?.isDebtRegistry) {
    if (!market) return null

    const debtType = debt?.debtType
    const debtRegistryMap = {
      se: {},
      no: {
        creditFacility: 'Rammekredit',
        repaymentLoan: 'Nedbetalingslån',
        chargeCard: 'Faktureringskort'
      },
      fi: {}
    }[market]

    information = debtRegistryMap[debtType]
  }

  return information || null
}
