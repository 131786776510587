import { removeNullValuesFromObject, roundUp, size, uppercaseFirstLetter } from '@ocp-zmarta/zmarta-cl'
import { defaultDebtValues } from './default-debt-values'
import { setDebtBankId } from './set-debt-bank-id'
import { setDebtCreditLimit } from './set-debt-credit-limit'
import { setDebtFee } from './set-debt-fee'
import { setDebtHolder } from './set-debt-holder'
import { setDebtInformation } from './set-debt-information'
import { setDebtInterestRate } from './set-debt-interest-rate'
import { setDebtKey } from './set-debt-key'
import { setDebtMonthlyCost } from './set-debt-monthly-cost'
import { setDebtTerms } from './set-debt-terms'
import { validDebt } from './valid-debt'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object}form
 * @param {object} debts
 * @param {string} type
 * @returns {[]}
 */
export function collectDebts ({ market, fields, form, debts, type } = {}) {
  if (!market || !fields || !form || !debts || !type) return []

  const list = []
  const hasDebtKey = `has${uppercaseFirstLetter(type)}s`
  const hasDebt = form?.debts?.[hasDebtKey]?.value ?? false
  const hasMultipleKey = `${type}Multiple`
  const hasMultiple = form.debts?.[hasMultipleKey]?.value ?? true
  const debtList = debts?.[type] || []

  // add single type debt to list
  if (!hasMultiple) {
    const amountKey = `${type}Amount`
    const monthlyCostKey = `${type}MonthlyCost`

    debtList.push({
      amount: form.debts?.[amountKey]?.value,
      monthlyCost: form.debts?.[monthlyCostKey]?.value
    })
  }

  if (!hasDebt || !size(debtList)) return list

  for (const [index, debt] of Object.entries(debtList)) {
    const newDebt = removeNullValuesFromObject({
      ...defaultDebtValues(fields),
      bank: setDebtBankId({ market, debt, type }),
      amount: roundUp(debt.amount) || 0,
      monthlyCost: setDebtMonthlyCost(debt) || 0,
      interestRate: setDebtInterestRate(debt),
      fee: setDebtFee(debt),
      type: setDebtKey({ debt, index, type }),
      applicant: setDebtHolder(debt),
      remainingRepaymentPeriod: setDebtTerms(debt),
      information: setDebtInformation({ market, debt }),
      creditLimit: setDebtCreditLimit(debt),
      refinance: debt.refinance ?? false,
      isDebtRegistry: debt.isDebtRegistry ?? false
    })

    if (validDebt(newDebt)) list.push(newDebt)
  }

  return list
}
