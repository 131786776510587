import { getBirthdateFromSsn } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} ssn
 * @param {string} market
 * @returns {string|undefined}
 */
export function getDobForMetaFromSsn ({ ssn, market } = {}) {
  if (!ssn || !market) return

  if (ssn.length >= 12 && market === 'se') {
    ssn = ssn.replace(/[^0-9]/g, '')
    const date = ssn.substr(2, 6)
    const uniqueNumber = ssn.substr(8, 4)
    ssn = date + '-' + uniqueNumber
  }

  const birthDate = getBirthdateFromSsn({ market, value: ssn })
  if (!birthDate?.year || !birthDate?.month || !birthDate?.day) return

  const birthYear = birthDate.year.toString().substring(2, 4)
  const birthMonth = birthDate.month < 10 ? `0${birthDate.month}` : birthDate.month
  const birthDay = birthDate.day < 10 ? `0${birthDate.day}` : birthDate.day

  return `${birthYear}${birthMonth}${birthDay}`
}
