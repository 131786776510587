/* istanbul ignore file */

import { isAuthError, isLeadError, isRateLimitError } from '@ocp-zmarta/zmarta-cl'
import requests from '../../requests'

/**
 * @param {string} name
 * @param {object|array|string} error
 * @param {array|undefined} error.graphQLErrors
 * @param {object} additionalData
 */
export function logError (name, error, additionalData = {}) {
  if (isAuthError(error) || isRateLimitError(error) || isLeadError(error)) return

  function replaceErrors (key, value) {
    if (!(value instanceof Error)) return value

    const error = {}

    Object
      .getOwnPropertyNames(value)
      .forEach(key => {
        error[key] = value[key]
      })

    return error
  }

  const graphQLError = error.graphQLErrors?.[0]?.name
  if (graphQLError) additionalData.graphQLError = graphQLError

  const data = {
    error: JSON.stringify(error, replaceErrors),
    ...additionalData
  }

  requests.internal.log('error', name, data)
    .then(() => true)
    .catch(() => false)
}
