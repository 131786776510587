/**
 * @param {number} typeId
 * @param {string} field
 * @returns {{show: (Array|boolean), fields: Array}}
 */
export function employmentFields (typeId, field = '') {
  // array of visible fields.
  let fields = []

  switch (typeId) {
    case 1: // Full time
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employer',
        'employedSince',
        'socialEconomicStatus',
        'profession'
      ]
      break
    case 2: // Self employed
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employer',
        'employedSince',
        'socialEconomicStatus',
        'profession'
      ]
      break
    case 6: // Freelancer
      break
    case 7: // Retired
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employedSince'
      ]
      break
    case 8: // Unemployed
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employedSince',
        'socialEconomicStatus'
      ]
      break
    case 9: // Student
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employedSince',
        'socialEconomicStatus'
      ]
      break
    case 13: // Parental leave
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employer',
        'employedSince',
        'socialEconomicStatus',
        'profession'
      ]
      break
    case 14: // Farmer
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employedSince',
        'socialEconomicStatus'
      ]
      break
    case 15: // Fixed Period
      fields = [
        'monthlyIncome',
        'monthlyNetIncome',
        'employer',
        'employedSince',
        'employedTo',
        'socialEconomicStatus',
        'profession'
      ]
      break
    default:
      fields = [
        'monthlyIncome',
        'monthlyNetIncome'
      ]
      break
  }

  return {
    show: fields?.includes(field),
    fields
  }
}
