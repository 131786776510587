import { historyPush } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    active: false
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getModal: state => state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async showModal ({ commit, dispatch }) {
      historyPush({ name: 'modal' })
      await dispatch('loader/hideLoader', {}, { root: true })
      await commit('mutateModal', true)

      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async hideModal ({ commit }) {
      return commit('mutateModal', false)
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateModal (state, active) {
      Vue.set(state, 'active', active)
    }
  }
}
