import { getBirthdateFromSsn, isEmpty } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} market
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setDefaultValues ({ market, fields = {}, form = {} } = {}) {
  if (!market) return fields

  // ? ***************************
  // ? *** NO
  // ? ***************************

  if (market === 'no') {
    // ? ***************************
    // ? *** inCountrySince
    // ? ***************************

    if (
      !isEmpty(fields?.applicant) &&
      !fields?.applicant?.inCountrySince &&
      fields?.applicant?.ssn
    ) {
      const date = getBirthdateFromSsn({ market, value: fields?.applicant?.ssn })

      fields.applicant.inCountrySince = {
        month: date?.month,
        year: date?.year
      }
    }

    if (
      !isEmpty(fields?.coApplicant) &&
      !fields?.coApplicant?.inCountrySince &&
      fields?.coApplicant?.ssn
    ) {
      const date = getBirthdateFromSsn({ market, value: fields?.coApplicant?.ssn })

      fields.coApplicant.inCountrySince = {
        month: date?.month,
        year: date?.year
      }
    }

    // ? ***************************
    // ? *** otherIncomeAmount
    // ? ***************************

    if (
      !isEmpty(fields?.applicant) &&
      !fields?.applicant?.otherIncomeAmount
    ) {
      fields.applicant.otherIncomeAmount = 0
    }

    if (
      !isEmpty(fields?.coApplicant) &&
      !fields?.coApplicant?.otherIncomeAmount
    ) {
      fields.coApplicant.otherIncomeAmount = 0
    }
  }

  return fields
}
