import { CONSTANTS, size } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {object} fields
 * @param {object} form
 * @returns {*}
 */
export function setAppType ({ fields = {}, form = {} } = {}) {
  fields.global = fields?.global || {}

  const loanAmount = parseInt(fields?.global?.loanAmount || '', 10)
  let refinance = fields?.global?.refinance
  let refinanceAmount = parseInt(fields?.global?.refinanceAmount || '', 10)

  if (!refinanceAmount && size(fields.debts)) {
    refinanceAmount = 0

    fields.debts.forEach(debt => {
      if (debt.refinance) refinanceAmount += debt.amount
    })

    if (refinanceAmount > loanAmount) refinanceAmount = loanAmount
    if (refinanceAmount) refinance = true
  }

  if (!refinance || !refinanceAmount || !loanAmount) {
    fields.global.appType = CONSTANTS.APP_TYPE.NEW
  } else if (loanAmount === refinanceAmount) {
    fields.global.appType = CONSTANTS.APP_TYPE.REFINANCE
    fields.global.loanPurpose = CONSTANTS.LOAN_PURPOSE.COLLECT_LOANS
  } else {
    fields.global.appType = CONSTANTS.APP_TYPE.NEW_PLUS_REFINANCE
  }

  return fields
}
