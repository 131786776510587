/**
 * @param {string|null} cid
 * @returns {boolean|*}
 */
export function getGaClientId () {
  const gaClientId = ''

  const cookie = typeof document !== 'undefined' && document.cookie
  if (!cookie) return gaClientId

  const match = cookie && cookie.match(/_ga=(.*?)(;|$)/)
  if (!match || !match[1]) return gaClientId

  const values = match[1].split('.')
  if (!values[2] || !values[3]) return gaClientId

  return `${values[2]}.${values[3]}`
}
