const verticalMap = {
  cl: 'consumer_loan',
  sme: 'sme_loan',
  mortgage: 'mortgage',
  epi: 'epi',
  insurance: 'insurance'
}

/**
 * @param {string} market
 * @param {string} vertical
 * @returns {{'x-auth-vertical': string, 'x-auth-market', 'x-auth-role': string}}
 */
export function serviceAuthHeaders (market, vertical) {
  return {
    'x-auth-market': market,
    'x-auth-vertical': verticalMap[vertical],
    'x-auth-role': 'customer',
    'x-auth-use-new-signicat-platform': true
  }
}
