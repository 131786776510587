import { hotjarTagRecording, isRulesEngineError } from '@ocp-zmarta/zmarta-cl'
import { metric } from '../misc'

// ? ******************************************************************************************
// ? ******************************************************************************************

/**
 * @param {string} type
 * @param {boolean} isLead
 * @param {string} locale
 * @param {string} channel
 * @param {object} error
 */
export function finalizeApplicationMetrics ({ type, isLead, locale = undefined, channel = undefined, error = undefined } = {}) {
  if (!type) return

  // ? ***********
  // ? Success
  // ? ***********

  if (type === 'success') {
    if (isLead) {
      metric('application', { action: 'finalize_lead', status: 'success', locale, channel })
      hotjarTagRecording('cl/form/application/finalize/lead')
    } else {
      metric('application', { action: 'finalize', status: 'success', locale, channel })
      hotjarTagRecording('cl/form/application/finalize')
    }
  }

  // ? ***********
  // ? Error
  // ? ***********

  if (type === 'error') {
    if (isLead) {
      if (isRulesEngineError(error)) {
        metric('application', { action: 'finalize_lead', status: 'rules_engine', locale, channel })
        hotjarTagRecording('cl/form/application/finalize/lead/error-rules-engine')
      } else {
        metric('application', { action: 'finalize_lead', status: 'error', locale, channel }, error)
        hotjarTagRecording('cl/form/application/finalize/lead/error')
      }
    } else {
      if (isRulesEngineError(error)) {
        metric('application', { action: 'finalize', status: 'rules_engine', locale, channel })
        hotjarTagRecording('cl/form/application/finalize/error-rules-engine')
      } else {
        metric('application', { action: 'finalize', status: 'error', locale, channel }, error)
        hotjarTagRecording('cl/form/application/finalize/error')
      }
    }
  }
}
