import { clone, getErrorName, replaceBrandVars } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    successMessage: null,
    statusMessage: null,
    modalMessage: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getSuccessMessage: state => state.successMessage,
    getStatusMessage: state => state.statusMessage,
    getModalMessage: state => state.modalMessage
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async setStatusMessage ({ commit, dispatch, rootGetters }, { group, name, show }) {
      let statusMessage = clone(
        rootGetters['content/getContent']?.statusMessages?.[group]?.[name] ||
        rootGetters['content/getContent']?.[group]?.default
      )

      statusMessage = replaceBrandVars({
        brand: Vue.prototype.BRAND,
        market: Vue.prototype.MARKET,
        vertical: Vue.prototype.VERTICAL,
        locale: rootGetters['translations/getLocale'],
        content: statusMessage,
        form: rootGetters['form/getForm']
      })

      await commit('mutateMessages', { statusMessage })
      if (show) await dispatch('loader/showLoader', {}, { root: true })

      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async resetStatusMessage ({ commit }) {
      return commit('mutateMessages', { statusMessage: null })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async setSuccessMessage ({ commit, dispatch, rootGetters }, { group, name, show }) {
      const modalMessage = clone(
        rootGetters['content/getContent']?.successMessages?.[group]?.[name] ||
        rootGetters['content/getContent']?.successMessages?.[group]?.default
      )

      if (modalMessage?.title) {
        modalMessage.title = replaceBrandVars({
          brand: Vue.prototype.BRAND,
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          locale: rootGetters['translations/getLocale'],
          content: modalMessage.title,
          form: rootGetters['form/getForm']
        })
      }

      if (modalMessage?.text) {
        modalMessage.text = replaceBrandVars({
          brand: Vue.prototype.BRAND,
          market: Vue.prototype.MARKET,
          vertical: Vue.prototype.VERTICAL,
          locale: rootGetters['translations/getLocale'],
          content: modalMessage.text,
          form: rootGetters['form/getForm']
        })
      }

      await commit('mutateMessages', { modalMessage })
      if (show) await dispatch('modal/showModal', {}, { root: true })

      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async setModalMessage ({ commit, dispatch, rootGetters }, { group, name, code, show, callbacks }) {
      const vars = {
        form: rootGetters['form/getForm'],
        brand: Vue.prototype.BRAND,
        market: Vue.prototype.MARKET,
        vertical: Vue.prototype.VERTICAL,
        locale: rootGetters['translations/getLocale']
      }

      const modalMessage = clone(
        rootGetters['content/getContent']?.modalMessages?.[group]?.[getErrorName(name)] ||
        rootGetters['content/getContent']?.modalMessages?.[group]?.default
      )

      if (modalMessage?.title) {
        modalMessage.title = replaceBrandVars({
          ...vars,
          content: modalMessage.title
        })
      }

      if (modalMessage?.text) {
        const errorCodeMessage = code
          ? `<span class="code">${rootGetters['content/getContent']?.common?.errorCode}: ${code}</span>`
          : ''

        modalMessage.text = replaceBrandVars({
          ...vars,
          content: modalMessage?.text?.replace('{code}', errorCodeMessage)
        })
      }

      if (modalMessage) modalMessage.callbacks = callbacks

      await commit('mutateMessages', { modalMessage })
      if (show) await dispatch('modal/showModal', {}, { root: true })

      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async resetModalMessage ({ commit }) {
      return commit('mutateMessages', { modalMessage: null })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateMessages (state, payload) {
      Vue.set(state, 'statusMessage', payload.statusMessage || null)
      Vue.set(state, 'modalMessage', payload.modalMessage || null)
    }
  }
}
