import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} metric
 * @param {object} labels
 * @returns {Promise<any>}
 */
export async function metric (metric, labels) {
  return request({
    method: 'POST',
    url: '/cl-form/metric',
    body: {
      metric,
      labels
    }
  })
}
