import { request } from '@ocp-zmarta/zmarta-cl'

export async function lookupCompanyIdentifier ({ orgNr, market }) {
  if (market !== 'no' && market !== 'fi') {
    return
  }
  let url = ''
  let headers = {}

  if (market === 'no') {
    url = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNr}`
  } else if (market === 'fi') {
    url = `https://avoindata.prh.fi/bis/v1/${orgNr}`
    headers = {
      Accept: 'application/json'
    }
  }

  try {
    const res = await request({
      method: 'GET',
      url,
      headers,
      failGracefully: true
    })

    switch (market) {
      case 'no':
        return { lookupCompanyIdentifier: res?.navn }
      case 'fi':
        return { lookupCompanyIdentifier: res?.results?.[0]?.name }
    }
  } catch (e) {
    return false
  }
}
